import { Injectable, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, last, map } from 'rxjs/operators';

import {
    IPagedResponse, IUser, IAdvancedSearchRequest, SortDirection, IContact, ITeam, IOrganization,
    ILanguageItem, ITheme, IGrapesJsJSON, AzavistaApiService, IGetReportResponse, IStage, IErrorNotification,
    BuiltInFieldName, ISearchOrganizationsResponse, ISearchOrganizationsRequest, IEventParticipant,
    IAsset, DocumentEntityType, ISearchSeriesEventsResponse, IFloorPlan, IProfilePage, IEventActivity,
    EventEntityUpdatedTriggerTriggerName, EventPublish, IJob, IJobFileDownloadData, RRuleWeekDay,
    BookingProcessStatus, ActivityParticipantStatus, EventType, PageSubtype, ITeamIds, IIntegration,
    DocumentEntityUploadOptions, IPagedRequest, IGetDocumentDownloadTokenResponse,
    IEventApp
} from '@azavista/servicelib';
import {
    IPagingOptions, IIdWithLabel, IGroup, IField, IRelationInputFieldValue, IAdvancedSearchData,
    IAzavistaAdvancedSearchComponentChangedData,
    FieldOperator, IAdvancedFilterCriteria, IValueChangesWithObject, IAzavistaGroupedFieldsComponentData,
    IFieldIdWithOptions, IValueTranslation, AzavistaSharedService, FieldComponentType,
    IObjectWithId, IInputFieldOptions, ISetDocumentFieldData, ISetImageFieldData, IValueTranslations, IFilterCriteria,
    IAzavistaSelectedItemsCountData,
    ITableSettingsChangedData,
    ISortingChangedData,
    InputFieldType,
    IAttributeTranslation,
    IIdWithName
} from '@azavista/components/shared';
import {
    ISearchItemsSettingsChangedData, AzavistaSearchItemsComponent, ISearchItemsData
} from '@azavista/components/search-items';
import { flatSearchToRecursive, FlatSearchParams, SearchParams } from '@azavista/advanced-search';
import { AzavistaInputFieldService, ICroppedImageEventArgs } from '@azavista/components/input-field';
import { IEventWithOptions } from '@azavista/components/calendar';
import { IDashboardWidgetCustomEventArgs } from '@azavista/components/dashboard';
import { IIntegrationPartial } from '@azavista/components/flow-builder/interfaces';
import { LanguageDirection, TranslationKey } from './enums';
import {
    IFieldIdWithAsset, IObjectEnumInputFieldValue, IProgressData, ISearchItemsSearchChangedCallbackResult,
    ISelectNameOrThemeDialogResult, ISequenceEventsForCalendar, ISelectNameDialogResult,
} from './shared.interfaces';
import { IConfirmationDialogComponentData } from './confirmation-dialog/confirmation-dialog.interfaces';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { AclService } from './acl.service';
import { RequiredScopesService } from './required-scopes.service';
import { environment } from '../../environments/environment';
import { DownloadFilesDialogComponent } from './download-files-dialog/download-files-dialog.component';
import { FileDownloadService } from './file-download.service';
import { CreateNewEntityComponent } from './create-new-entity/create-new-entity.component';
import { ICreateNewEntityComponentData } from './create-new-entity/create-new-entity.interfaces';
import { IDataPointSelectedArguments } from './widgets/apexcharts-wrapper/interfaces';
import { IParticipantsByStageSettings, IParticipantsByStageSettingsLocal } from './widgets/participants-by-stage/interfaces';
import { IParticipantGroupBySettings } from './widgets/participants-by-type/interfaces';
import { WidgetCustomEventType } from './widgets/enums';
import { WidgetDefinitionDataType } from './widgets/interfaces';
import { IDownloadFilesDialogComponentData, IDownloadItem } from './download-files-dialog/interfaces';
import { SubjectsService } from './subjects.service';
import { IActivityParticipantGroupByStatusSettings } from './widgets/activity-participant-group-by-status/interfaces';
import { IEventActivityDetailsRouteState, EventActivityDetailsTabName, IEventActivityDetailsQueryParams } from '../event/activities/event-content-activities.interfaces';
import { ICountdownClockWidgetClickArgs } from './widgets/countdown-clock/interfaces';
import { TRANSLATION_MAP, TranslationMapKey } from './const';
import { DateFormatPipe } from './pipes/date-format.pipe';

export function getStringEnumValues(enumData: Record<string, string>): string[] {
    return Object.entries(enumData).map(
        ([_, status]) => status
    );
}

export const asyncEvery = async <RowType>(array: RowType[], predicate: (data: RowType) => Promise<boolean>) => {
    for (const row of array) {
        if (!await predicate(row)) return false;
    }
    return true;
};

export function getFieldValueFromSelection<Data extends Partial<IObjectWithId>, FieldValue extends Data[string]>
    (selection: SelectionModel<Data['id']>, dataRows: Data[], selector: (dataItem: Data) => FieldValue): FieldValue | null {
    const foundDataRows = dataRows.filter(({ id }) => selection.selected.includes(id));
    const isAllSelectedIdsFound = selection.selected.length > 0 && foundDataRows.length === selection.selected.length;
    if (isAllSelectedIdsFound) {
        const firstRowValue = selector(foundDataRows[0]);
        const allRowsHasSameValue = foundDataRows.every(row => selector(row) === firstRowValue);
        return allRowsHasSameValue ? firstRowValue : null;
    }
    return null;
}

export function getDisplayedParticipantName(eventParticipant: Pick<IContact, 'first_name' | 'last_name' | 'email'>): string {
    const fullName = ((eventParticipant.first_name ?? '') + ' ' + (eventParticipant.last_name ?? '')).trim();
    return fullName || eventParticipant.email;
}


type MapByDataField<Data extends Partial<IObjectWithId>, Property extends keyof Data = 'id'> = {
    [id in Data[Property]]: Data;
};

export function getMapFromDataArray<Data extends Partial<IObjectWithId>, Field extends keyof Data>
    (rows: Data[], propertyName: Field = 'id' as any): MapByDataField<Data, Field> {
    return rows.reduce((map, row) => {
        map[row[propertyName]] = row;
        return map;
    }, {} as MapByDataField<Data, Field>);
}

/**
 * Efficiently get multiple items from Array by using `map`/`hashtable`.
 *
 * This is an improvement rather than using `arrayOfValue.map(value => arrayOfItems.find(arrayOfItems.value === value))`
 * */
export function getItemsFromArrayByValue<Data extends Partial<IObjectWithId>, Property extends keyof Data>
    (rows: Data[], valuesToBeFound: Data[Property][], propertyName: Property = 'id' as any): (Data | undefined)[] {
    const mapBasedOnValue = getMapFromDataArray<Data, Property>(rows, propertyName);
    return valuesToBeFound.map(value => mapBasedOnValue[value]);
}

export function pick<Data extends Object, Props extends Array<keyof Data>>(data: Data, propsNames: Props): Pick<Data, Props[number]> {
    return Object.entries(data).reduce((result, [key, value]) => {
        const keyWithType = key as keyof Data;
        if (propsNames.includes(keyWithType)) {
            result[keyWithType] = value;
        }
        return result;
    }, {} as Partial<Data>) as any;
}

export const runPromisesSequentially = async <T>(promisesFn: Array<() => Promise<T>>) => {
    return promisesFn.reduce(async (result, promiseFn) => {
        const previous = await result;
        previous.push(await promiseFn());
        return previous;
    }, Promise.resolve<T[]>([]));
};

type GetFormattedDateTimeRangeConfig = {
    startDate: string,
    endDate: string
    dateFormat: string,
    timeFormat: string,
    timezone: string,
}

const kilo = 1024;
const mega = Math.pow(kilo, 2);
const giga = Math.pow(kilo, 3);

const API_RESPONSE_CODE_DOCUMENT_NOT_FOUND = 'ObjectNotFound';
type ITableSettingsChangedDataForRequest = {
    paging: Pick<IPagingOptions, 'limit' | 'offset'>
} & Partial<Pick<ITableSettingsChangedData, 'advancedSearch' | 'sorting'>>;

/** Record with key of EventApp's Url and value of EventApp's name */
export type EventAppUrlRecord = {
    [eventAppUrl: string]: string;
};

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    /** renderer should be initiated in `AppComponent.ngOnInit` */
    renderer: Renderer2;
    objectUrls: Set<string> = new Set<string>();
    private readonly preferredLanguageFieldName = 'preferred_language';
    private readonly rightToLeftLanguages = new Set(['he-IL', 'ar-AE']);

    constructor(
        private translateSvc: TranslateService, private matDialog: MatDialog, private aclSvc: AclService,
        private rsSvc: RequiredScopesService, private sanitizer: DomSanitizer,
        private downloadSvc: FileDownloadService, private apiSvc: AzavistaApiService,
        private inputFieldSvc: AzavistaInputFieldService, private cmpSharedSvc: AzavistaSharedService,
        private router: Router, private subjectSvc: SubjectsService, private decimalPipe: DecimalPipe,
        private readonly dateFormatPipe: DateFormatPipe,
    ) {
    }

    getCurrentUser(): IUser {
        return this.apiSvc.getCurrentUser();
    }

    addItemAtIndex<TItem>(items: TItem[], itemToInsert: TItem, insertIndex: number): TItem[] {
        const itemsBefore = items.slice(0, insertIndex);
        const itemsAfter = items.slice(insertIndex);
        const result = [
            ...itemsBefore,
            itemToInsert,
            ...itemsAfter,
        ];
        return result;
    }

    async delay(ms: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                return resolve();
            }, ms);
        });
    }

    async retry(fn: () => void | Promise<void>, retries: number, delay: number): Promise<any> {
        for (let i = 0; i < retries; i++) {
            try {
                await fn();
                break;
            } catch (err) {
                await this.delay(delay);
            }
        }
    }

    getLanguageDirection(language: string): LanguageDirection {
        const isLeftToRightLanguage = !this.rightToLeftLanguages.has(language);
        return isLeftToRightLanguage ? LanguageDirection.ltr : LanguageDirection.rtl;
    }

    getPreferredLanguageFieldName(): string {
        return this.preferredLanguageFieldName;
    }

    getDuplicatedItems<TItem>(items: TItem[], itemKeySelector?: (item: TItem) => string): TItem[] {
        const duplicatedItems: Record<string, TItem> = {};
        const obj: Record<string, boolean> = {};
        for (const item of items) {
            const itemKey = itemKeySelector ? itemKeySelector(item) : '' + item;
            if (!obj[itemKey]) {
                obj[itemKey] = true;
            } else {
                duplicatedItems[itemKey] = item;
            }
        }
        const duplicatedItemsArray = Object.keys(duplicatedItems).map(x => duplicatedItems[x]);
        return duplicatedItemsArray;
    }

    objectHasAttribute(obj: object, attributeName: string): boolean {
        return Object.getOwnPropertyNames(obj).indexOf(attributeName) >= 0;
    }

    isNullOrUndefined(value: unknown | null | undefined): boolean {
        return (value === null || value === undefined);
    }

    createTextField(id: string, name?: string, labelTranslationKey?: TranslationKey | TranslationMapKey): IField {
        const field: IField = {
            id: id,
            name: name || id,
            label: labelTranslationKey ? this.translate(labelTranslationKey) : null,
            editable: true,
            schema: { type: 'string' }
        } as IField;
        return field;
    }

    getExternalCrmThrottlingTimeOrDefault(throttlingTime?: number): number {
        return throttlingTime > 0 ? throttlingTime : 30;
    }

    translatePageSubtype(pageSubtype: PageSubtype): string {
        const participantStatusTranslationKeyPrefix = 'PAGE_SUBTYPE_';
        return this.translate((participantStatusTranslationKeyPrefix + pageSubtype.toUpperCase()) as TranslationKey);
    }

    getNonFalsyItems<TItem>(items: TItem[], valueSelector?: (item: TItem) => any): TItem[] {
        const result: TItem[] = [];
        for (const item of items) {
            if (!!item) {
                if (valueSelector) {
                    const value = valueSelector(item);
                    if (!!value) {
                        result.push(item);
                    }
                } else {
                    result.push(item);
                }
            }
        }
        return result;
    }

    translateParticipantActivityStatus(status: ActivityParticipantStatus): string {
        const participantStatusTranslationKeyPrefix = 'PARTICIPANT_ACTIVITY_STATUS_';
        return this.translate((participantStatusTranslationKeyPrefix + status.toUpperCase()) as TranslationKey);
    }

    async showSingleDropDownDialog<TItem>(
        allItems: TItem[], fieldName: string, labelTranslationKey: TranslationKey,
        selectedItem?: TItem, selectedItemsCountData?: IAzavistaSelectedItemsCountData
    ): Promise<{ [key: string]: TItem }> {
        const groupedFields: IAzavistaGroupedFieldsComponentData = {
            editModeForAllFields: true, expandPanels: true, singleColumn: true, showActionButtons: true,
            fieldsWithEntity: {
                entity: {},
                fields: []
            },
            disableAutoOrdering: true,
            selectedItemsCountData: selectedItemsCountData
        };
        const fieldValue: IObjectEnumInputFieldValue = {
            items: allItems,
            selected: selectedItem,
            displayProperty: 'name'
        };
        groupedFields.fieldsWithEntity.entity[fieldName] = fieldValue;
        groupedFields.fieldsWithEntity.fields.push(
            {
                id: fieldName, name: fieldName, label: this.translate(labelTranslationKey), category: '', editable: true, component: 'object_enum',
                schema: { type: 'object' }, mandatory_for_planners: true
            } as IField
        );

        const actionSubject = new Subject<IValueChangesWithObject>();
        const newEntityData: ICreateNewEntityComponentData = {
            groupedFields: groupedFields, actionSubject: actionSubject,
            title: ''
        };
        const newTeamDialogRef = this.matDialog.open(CreateNewEntityComponent, {
            data: newEntityData,
            width: this.getNewDialogCssWidth()
        });
        return new Promise(resolve => {
            const actionSubscription = actionSubject.subscribe({
                next: async data => {
                    if (!data) {
                        // Close button is clicked
                        actionSubscription.unsubscribe();
                        newTeamDialogRef.close();
                        return resolve(null);
                    }
                    const obj = data.obj;
                    if (!obj) {
                        actionSubscription.unsubscribe();
                        newTeamDialogRef.close();
                        return resolve(null);
                    }
                    actionSubscription.unsubscribe();
                    newTeamDialogRef.close();
                    return resolve(obj);
                }
            });
        });
    }

    removeNullOrUndefinedEnumItemFromFields(fields: IField[]): void {
        for (const field of fields) {
            if (field.schema.enum) {
                field.schema.enum = field.schema.enum.filter(x => x !== null && x !== undefined);
            }
        }
    }

    sortByArray<TItem, TKey>(items: TItem[], propertiesOrder: TKey[], propertyOrderKeySelector: (item: TItem) => TKey): TItem[] {
        const sorted: TItem[] = [];
        const notSorted: TItem[] = [];
        for (let i = 0; i < propertiesOrder.length; i++) {
            const currentPropertyOrder = propertiesOrder[i];
            const sortableItem = items.find(x => propertyOrderKeySelector(x) === currentPropertyOrder);
            if (sortableItem) {
                sorted.push(sortableItem);
            } else {
                notSorted.push(sortableItem);
            }
        }
        const result = sorted.concat(notSorted);
        return result;
    }

    getFilteredColumnsForAdvancedFilter(fields: IField[]): IField[] {
        const filteredFields: IField[] = [];
        for (const field of fields) {
            const fieldType = this.cmpSharedSvc.getFieldTypeFromField(field);
            if (fieldType !== 'document') {
                filteredFields.push(field);
            }
        }
        return filteredFields;
    }

    getFilteredColumnsForLists(fields: IField[]): IField[] {
        const filteredFields: IField[] = [];
        for (const field of fields) {
            const fieldType = this.cmpSharedSvc.getFieldTypeFromField(field);
            if (fieldType !== 'document' && fieldType !== 'image') {
                filteredFields.push(field);
            }
        }
        return filteredFields;
    }

    getSelectAndMultiSelectFields(fields: IField[]): IField[] {
        const selectAndMultiSelectFields = fields.filter(x => {
            const fieldType = this.cmpSharedSvc.getFieldTypeFromField(x);
            return fieldType === 'select' || fieldType === 'multi-select';
        });
        return selectAndMultiSelectFields;
    }

    getFieldTypeFromField(field: IField): InputFieldType {
        return this.cmpSharedSvc.getFieldTypeFromField(field);
    }

    getEnumOrArrayFieldItems(field: IField): string[] {
        let items: string[];
        const fieldType = this.cmpSharedSvc.getFieldTypeFromField(field);
        if (fieldType === 'select') {
            items = field.schema.enum as string[];
        } else if (fieldType === 'multi-select') {
            items = ((field.schema.items as any)?.enum as string[]);
        }
        items ||= [];
        return items;
    }

    removeNonUpdateablePropertiesOfBuiltInField(field: IField): IField {
        const clonedField = this.clone(field);
        const result = this.getFieldWithoutCustomAttributes(clonedField);
        if (field.builtin || field.crm_field_id) {
            delete result.attributeTranslations;
        }
        if (!field.builtin) {
            return result;
        }
        delete result.schema;
        delete result.name;
        delete result.type;
        delete result.component;
        return result;
    }

    getFieldWithoutCustomAttributes(field: IField): IField {
        const result: IField = { ...field };
        const keys = Object.keys(result);
        for (const key of keys) {
            if (key.startsWith('__')) {
                delete (result as any)[key];
            }
        }
        return result;
    }

    setFieldTypeNameValue(fields: IField[]): void {
        const fieldTypeNameFieldName = this.getFieldTypeNameFieldName();
        for (const field of fields) {
            const fieldType = (field.type?.toUpperCase() || 'TEXT').replace(/-/g, '_');
            const key = `FIELD_TYPE_${fieldType}` as TranslationKey;
            (field as any)[fieldTypeNameFieldName] = this.translate(key);
        }
    }

    getFieldTypeNameFieldName(): string {
        return '__typeName';
    }

    changeFilterCriteriaNamesToIds(filterCriteria: IFilterCriteria[], idNameMap: IIdWithName[], fieldId: string): void {
        if (idNameMap && idNameMap.length > 0) {
            // Map names back to ids
            for (const criteria of filterCriteria) {
                if (criteria.field.id === fieldId) {
                    if (Array.isArray(criteria.value)) {
                        for (let i = 0; i < criteria.value.length; i++) {
                            const value = criteria.value[i];
                            const mapItem = idNameMap.find(x => x.name === value);
                            if (mapItem) {
                                criteria.value[i] = mapItem.id;
                            }
                        }
                    } else {
                        const value = '' + criteria.value;
                        const mapItem = idNameMap.find(x => x.name === value);
                        if (mapItem) {
                            criteria.value = mapItem.id;
                        }
                    }
                }
            }
        }
    }

    setTeamIdsToEntities(entities: ITeamIds[], teamIds: number[]): void {
        if (!entities) {
            return;
        }
        for (const entity of entities) {
            entity.team_ids = teamIds;
        }
    }

    getTranslatedWeekDayRepetitionRules(): IIdWithName[] {
        const items: IIdWithName[] = [
            { id: RRuleWeekDay.monday, name: this.translate(TranslationKey.modnay) },
            { id: RRuleWeekDay.tuesday, name: this.translate(TranslationKey.tuesday) },
            { id: RRuleWeekDay.wednesday, name: this.translate(TranslationKey.wednesday) },
            { id: RRuleWeekDay.thursday, name: this.translate(TranslationKey.thursday) },
            { id: RRuleWeekDay.friday, name: this.translate(TranslationKey.friday) },
            { id: RRuleWeekDay.saturday, name: this.translate(TranslationKey.saturday) },
            { id: RRuleWeekDay.sunday, name: this.translate(TranslationKey.sunday) },
        ];
        return items;
    }

    processWidgetCustomEvent(args: IDashboardWidgetCustomEventArgs): void {
        const type = args.widgetCustomEventArgs.type;
        const dataType = args.widgetComponentDefinition?.dataType;
        if (type === WidgetCustomEventType.dataPointSelected) {
            // TODO: Is dataType unique ?
            if (dataType === WidgetDefinitionDataType.participantsByStage) {
                this.processParticipantsByStageDataPointSelected(args);
            } else if (dataType === WidgetDefinitionDataType.participantsByEventType) {
                this.processParticipantsByFieldDataPointSelected(args);
            } else if (dataType === WidgetDefinitionDataType.activityParticipantGroupByStatus) {
                this.processActivityParticipantGroupByStatusSelected(args);
            }
        } else if (type === WidgetCustomEventType.click) {
            if (dataType === WidgetDefinitionDataType.countdownClock) {
                this.processCountdownClockClicked(args);
            }
        }
    }

    processCountdownClockClicked(args: IDashboardWidgetCustomEventArgs): void {
        const clickArgs = args.widgetCustomEventArgs.data as ICountdownClockWidgetClickArgs;
        const queryParams = {
            eventId: clickArgs.eventId,
            type: EventType.event
        };
        this.router.navigate(['event', 'dashboard'], { queryParams: queryParams });
    }

    processActivityParticipantGroupByStatusSelected(args: IDashboardWidgetCustomEventArgs): void {
        // Navigate to activity participants
        const widgetSettings = args.widgetComponentDefinition.settings as IActivityParticipantGroupByStatusSettings;
        const queryParams: IEventActivityDetailsQueryParams = {
            eventId: widgetSettings.remote.event_id,
            activityId: widgetSettings.remote.activity_id
        };
        const routeState: IEventActivityDetailsRouteState = {
            tabName: EventActivityDetailsTabName.activityParticipants
        };
        this.router.navigate(['event', 'activities', 'activity'], { queryParams: queryParams, state: routeState });
    }

    processParticipantsByFieldDataPointSelected(args: IDashboardWidgetCustomEventArgs): void {
        const dataPointSelectedArgs = args.widgetCustomEventArgs.data as IDataPointSelectedArguments;
        const languageCodes = Object.keys(args.widgetComponentDefinition.data);
        const selectedLanguageCode = languageCodes[dataPointSelectedArgs.dataPointIndex];
        if (selectedLanguageCode) {
            const widgetSettings = args.widgetComponentDefinition.settings as IParticipantGroupBySettings;
            const queryParams = {
                eventId: widgetSettings.remote.event_id,
                filterFieldId: widgetSettings.remote.field_name,
                filterFieldValue: selectedLanguageCode
            };
            this.subjectSvc.getParticipantsCustomWidgetFilter().next(widgetSettings.remote.participant_filter);
            this.router.navigate(['event', 'participants', 'list'], { queryParams: queryParams });
        }
    }

    processParticipantsByStageDataPointSelected(args: IDashboardWidgetCustomEventArgs): void {
        const dataPointSelectedArgs = args.widgetCustomEventArgs.data as IDataPointSelectedArguments;
        const widgetLocalSettings = args.widgetComponentDefinition.settings.local as IParticipantsByStageSettingsLocal;
        const displayStages = widgetLocalSettings.display_stages;
        const selectedStageId = displayStages[dataPointSelectedArgs.dataPointIndex];
        const stages = args.widgetComponentDefinition.data as IStage[];
        const selectedStage = stages.find(x => x.id === selectedStageId);
        if (selectedStage) {
            const widgetSettings = args.widgetComponentDefinition.settings as IParticipantsByStageSettings;
            const queryParams = { eventId: widgetSettings.remote.event_id, stageId: selectedStage.id };
            this.router.navigate(['event', 'participants', 'list'], { queryParams: queryParams });
        }
    }

    getTimePartWithZeroSeconds(dateTime: string): string {
        return dateTime.substr(11, 5) + ':00';
    }

    getBookingProcessStatusTriggerTranslations(language: string): IValueTranslations {
        const translations: IValueTranslations = {
            [language]: {
                [BookingProcessStatus.approved]: this.translate(TranslationKey.approved),
                [BookingProcessStatus.pending]: this.translate(TranslationKey.pending),
                [BookingProcessStatus.rejected]: this.translate(TranslationKey.rejected),
                [BookingProcessStatus.requested]: this.translate(TranslationKey.requested)
            }
        };
        return translations;
    }

    getAllEventPublishTriggerNames(): EventPublish[] {
        const allTriggers: EventPublish[] = [
            EventPublish.PUBLISHED,
            EventPublish.NO_REGISTRATION,
            EventPublish.OFF_LINE,
            EventPublish.CANCELLED,
            EventPublish.APPROVED,
            EventPublish.DRAFT,
        ];
        return allTriggers;
    }

    getEventPublishTriggerTranslations(language: string): IValueTranslations {
        const translations: IValueTranslations = {
            [language]: {
                [EventPublish.PUBLISHED]: this.translate(TranslationKey.published),
                [EventPublish.NO_REGISTRATION]: this.translate(TranslationKey.noRegistration),
                [EventPublish.OFF_LINE]: this.translate(TranslationKey.offline),
                [EventPublish.CANCELLED]: this.translate(TranslationKey.cancelled),
                [EventPublish.APPROVED]: this.translate(TranslationKey.approved),
                [EventPublish.DRAFT]: this.translate(TranslationKey.draft),
            }
        };
        return translations;
    }

    getAllEventEntityUpdatedTriggerTriggerNames(): EventEntityUpdatedTriggerTriggerName[] {
        const allTriggers: EventEntityUpdatedTriggerTriggerName[] = [
            EventEntityUpdatedTriggerTriggerName.addTeams,
            EventEntityUpdatedTriggerTriggerName.create,
            EventEntityUpdatedTriggerTriggerName.removeTeams,
            EventEntityUpdatedTriggerTriggerName.replaceTeams,
            EventEntityUpdatedTriggerTriggerName.settings,
            EventEntityUpdatedTriggerTriggerName.update,
        ];
        return allTriggers;
    }

    getAllEventEntityUpdatedTriggerTranslations(language: string): IValueTranslations {
        const translations: IValueTranslations = {
            [language]: {
                [EventEntityUpdatedTriggerTriggerName.addTeams]: this.translate(TranslationKey.addTeams),
                [EventEntityUpdatedTriggerTriggerName.create]: this.translate(TranslationKey.create),
                [EventEntityUpdatedTriggerTriggerName.removeTeams]: this.translate(TranslationKey.removeTeams),
                [EventEntityUpdatedTriggerTriggerName.replaceTeams]: this.translate(TranslationKey.replaceTeams),
                [EventEntityUpdatedTriggerTriggerName.settings]: this.translate(TranslationKey.settings),
                [EventEntityUpdatedTriggerTriggerName.update]: this.translate(TranslationKey.update)
            }
        };
        return translations;
    }

    getErrorText(err: IErrorNotification): string {
        if (err.error && err.error.communicationError && err.error.communicationError.body) {
            const errBody = err.error.communicationError.body;
            // TODO: Change IErrorNotification so it contains code and message
            const errBodyAny = errBody as any;
            if (errBody.error || errBody.error_description) {
                return this.normalizeString(errBody.error_description) + ` (${this.normalizeString(errBody.error)})`;
            } else if (errBodyAny.code || errBodyAny.message) {
                let bodyMsg = this.normalizeString(errBodyAny.message) + ` (${this.normalizeString(errBodyAny.code)})`;
                if (errBodyAny.data && errBodyAny.data.errors) {
                    const errorsArr = Array.isArray(errBodyAny.data.errors) ? errBodyAny.data.errors as string[] : errBodyAny.data.errors as any[];
                    bodyMsg += ' ' + errorsArr.map(x => this.normalizeString(x)).join('; ');
                }
                return bodyMsg;
            }
        }
        const strings: string[] = [];
        if (err?.error?.communicationError?.status === 0) {
            this.addNormalizedStringsToArray(strings, [this.translate(TRANSLATION_MAP.THIS_MIGHT_BE_NETWORK_CONNECTIVITY_ERROR)]);
        }
        this.addNormalizedStringsToArray(strings, [err.caller, err.method, err.url]);
        const commErr = err.error.communicationError;
        if (commErr) {
            this.addNormalizedStringsToArray(strings, [commErr.statusText, commErr.message]);
        }
        const thrownErr = err.error.thrownError;
        if (thrownErr) {
            this.addNormalizedStringsToArray(strings, [thrownErr.message]);
        }
        const msg = strings.join(' ');
        return msg;
    }

    addNormalizedStringsToArray(arr: string[], values: string[]): void {
        for (const value of values) {
            arr.push(this.normalizeString(value));
        }
    }

    async getPartialIntegrations(): Promise<IIntegrationPartial[]> {
        const allIntegrations = await this.getAllIntegrations();
        const partialIntegrations: IIntegrationPartial[] = allIntegrations
            .map(x => ({ id: x.id, type: x.type, name: x.name, subtype: x.subtype }));
        return partialIntegrations;
    }

    async getAllIntegrations(): Promise<IIntegration[]> {
        const allIntegrtions = await this.apiSvc.getAllPagedItems(
            req => this.apiSvc.searchIntegrations(req),
            res => res.integrations
        );
        return allIntegrtions;
    }

    async deleteProcessesWithConfirmation(ids: string[], selectedItemsData?: IAzavistaSelectedItemsCountData): Promise<IConfirmationDialogComponentData> {
        const confirmResult = await this.showDeleteProcessesConfirmation(selectedItemsData);
        if (!confirmResult) {
            return confirmResult;
        }
        const promises = [];
        for (const id of ids) {
            promises.push(this.apiSvc.deleteProcess(id));
        }
        await Promise.all(promises);
        return confirmResult;
    }

    async showDeleteProcessesConfirmation(selectedItemsData?: IAzavistaSelectedItemsCountData): Promise<IConfirmationDialogComponentData> {
        return this.showConfirmationDialog(
            this.translate(TranslationKey.deleteProcessDialogTitle),
            [
                this.translate(TranslationKey.deleteProcessDialogAreYouSure),
                this.translate(TranslationKey.wontBeAbleToRestoreData)
            ],
            false,
            selectedItemsData
        );
    }

    getProcessesColumnsNoTypeAndSubType(): IField[] {
        const filtered = this.getProcessesColumns().filter(x => x.name !== 'type' && x.name !== 'subtype');
        return filtered;
    }

    getProcessesTriggerNameFieldName(): string {
        return '__trigger_name';
    }

    getProcessesTriggerValueFieldName(): string {
        return '__trigger_value';
    }

    getProcessesColumns(): IField[] {
        const triggerNameFieldName = this.getProcessesTriggerNameFieldName();
        const triggerValueFieldName = this.getProcessesTriggerValueFieldName();
        const category = this.translate(TranslationKey.processFields);
        return [
            {
                id: 'name', name: 'name', label: this.translate(TranslationKey.name), category: category,
                schema: { type: 'string' }, editable: true, builtin: true, visible_for_planners: true
            },
            {
                id: 'type', name: 'type', label: this.translate(TranslationKey.type), category: category,
                schema: { type: 'string' }, editable: false, builtin: true, visible_for_planners: true
            },
            {
                id: 'subtype', name: 'subtype', label: this.translate(TranslationKey.subtype), category: category,
                schema: { type: 'string' }, editable: false, builtin: true, visible_for_planners: true
            },
            {
                id: triggerNameFieldName, name: triggerNameFieldName, label: this.translate(TranslationKey.triggerName), category: category,
                schema: { type: 'string' }, editable: false, builtin: false, visible_for_planners: false
            },
            {
                id: triggerValueFieldName, name: triggerValueFieldName, label: this.translate(TranslationKey.triggerValue),
                category: category,
                schema: { type: 'string' }, editable: false, builtin: false, visible_for_planners: false
            }
        ];
    }

    getSequenceEventsForCalendar(response: ISearchSeriesEventsResponse): ISequenceEventsForCalendar {
        const result = {
            eventsWithParticipants: response.separate_events,
            eventsWithoutParticipants: []
        } as ISequenceEventsForCalendar;
        result.eventsWithoutParticipants = [];
        const sequencesKeys = Object.keys(response.sequences);
        for (const key of sequencesKeys) {
            result.eventsWithoutParticipants.push(...response.sequences[key]);
        }
        return result;
    }

    createEventsWithOptions(
        eventsForCalendar: ISequenceEventsForCalendar,
        eventsWithParticipantsClassNames: string[],
        eventsWithoutParticipantsClassNames: string[]
    ): IEventWithOptions[] {
        const eventsWithOptions: IEventWithOptions[] = [];
        for (const item of eventsForCalendar.eventsWithParticipants) {
            eventsWithOptions.push({
                eventId: item.id,
                options: { classNames: eventsWithParticipantsClassNames }
            });
        }
        for (const item of eventsForCalendar.eventsWithoutParticipants) {
            eventsWithOptions.push({
                eventId: item.id,
                options: { classNames: eventsWithoutParticipantsClassNames }
            });
        }
        return eventsWithOptions;
    }

    executeOnNextLoop(func: () => void): void {
        window.setTimeout(() => func());
    }

    async loadImageAndDocumentFieldsContent(
        allFields: IField[], entity: any, setImageFieldDataSubject: Subject<ISetImageFieldData>,
        setDocumentFieldDataSubject: Subject<ISetDocumentFieldData>
    ): Promise<void> {
        // Load all image fields content
        await this.loadAllImageFieldsContent(
            allFields, entity, setImageFieldDataSubject
        );

        // Send all document fields data if they have documents attached so they can show the download link
        this.setAllDocumentFieldsDownloadLinks(
            allFields, entity, setDocumentFieldDataSubject
        );
    }

    async uploadDocumentField(
        ownerEntityId: string, existingOwnerEntity: any, documentEntityType: DocumentEntityType, field: IField, file: File,
        setDocumentFieldDataSubject: Subject<ISetDocumentFieldData>
    ): Promise<IAsset> {
        return new Promise<IAsset>(async (resolve, reject) => {
            let obs: Observable<HttpEvent<IAsset>>;
            const existingDocumentId = existingOwnerEntity[field.name] as string;
            const uploadNewEntityDocument$ = this.apiSvc.uploadEntityDocumentFile(documentEntityType, ownerEntityId, file);
            if (existingDocumentId) {
                // There is already document for that field - update it
                obs = this.apiSvc.updateDocumentFile(existingDocumentId, file).pipe(
                    catchError(err => {
                        // if the related documentId is not found then uploadNewEntityDocument
                        return err?.error?.code === API_RESPONSE_CODE_DOCUMENT_NOT_FOUND ? uploadNewEntityDocument$ : throwError(() => err);
                    })
                );
            } else {
                obs = uploadNewEntityDocument$;
            }
            obs.pipe(
                map(ev => {
                    if (ev.type === HttpEventType.UploadProgress) {
                        const percentage = Math.round(100 * (ev.loaded / ev.total));
                        setDocumentFieldDataSubject.next({
                            fieldId: field.id,
                            percent: percentage
                        } as ISetDocumentFieldData);
                    } else if (ev.type === HttpEventType.Response) {
                        return ev.body;
                    }
                }),
                last()
            ).subscribe({
                next: data => {
                    setDocumentFieldDataSubject.next({
                        fieldId: field.id, link: data.filename, percent: 100
                    } as ISetDocumentFieldData);
                    return resolve(data);
                },
                error: err => {
                    // Send error to the field
                    setDocumentFieldDataSubject.next({
                        fieldId: field.id, error: `Can't upload the file`
                    } as ISetDocumentFieldData);
                    return reject(err);
                }
            });
        });
    }

    async uploadAllDocumentFieldsAndSetAssetsToEntity(
        allFields: IField[], ownerEntityId: string, ownerEntity: any, existingOwnerEntity: any,
        documentEntityType: DocumentEntityType,
        setDocumentFieldDataSubject: Subject<ISetDocumentFieldData>, progressSubject: Subject<IProgressData>
    ): Promise<IFieldIdWithAsset[]> {
        const fieldIdsWithAssets: IFieldIdWithAsset[] = [];
        const documentFieldsWithFile = allFields.filter(x => x.type === 'document' && !!ownerEntity[x.name]);
        for (let i = 0; i < documentFieldsWithFile.length; i++) {
            const field = documentFieldsWithFile[i];
            const file = ownerEntity[field.name] as File;
            if (file) {
                const subscr = setDocumentFieldDataSubject.subscribe({
                    next: docFieldData => {
                        progressSubject.next({
                            current: i + 1, total: documentFieldsWithFile.length,
                            currentPercentage: docFieldData.percent, currentName: file.name
                        });
                    }
                });
                try {
                    const asset = await this.uploadDocumentField(
                        ownerEntityId, existingOwnerEntity, documentEntityType, field, file, setDocumentFieldDataSubject
                    );
                    ownerEntity[field.name] = asset.id;
                    fieldIdsWithAssets.push({ fieldId: field.id, asset: asset });
                } catch (err) {
                    progressSubject.complete();
                    throw err;
                } finally {
                    subscr.unsubscribe();
                }
            }
        }
        // const documentFieldsWithoutFileToBeDeleted = allFields.filter(x => x.type === 'document' && !ownerEntity[x.name] && !!existingOwnerEntity[x.name]);
        // await Promise.all(documentFieldsWithoutFileToBeDeleted.map(
        //     field => this.apiSvc.deleteDocument(existingOwnerEntity[field.name])
        // ));
        progressSubject.complete();
        return fieldIdsWithAssets;
    }

    async uploadImageField(
        ownerEntityId: string, existingOwnerEntity: any, documentEntityType: DocumentEntityType,
        field: IField, imageBytes: Uint8Array
    ): Promise<IAsset> {
        let promise: Promise<IAsset>;
        const documentId = existingOwnerEntity[field.name];
        const uploadOptions = optionsByFieldName[field.name]?.[documentEntityType];
        const createEntityDocument = () => this.apiSvc.uploadEntityDocument(documentEntityType, ownerEntityId, field.label, 'image/png', imageBytes, uploadOptions);
        if (documentId) {
            // Already exists - update
            promise = (this.apiSvc.updateDocument(documentId, 'image', 'image/png', imageBytes)).catch((err) => {
                if (err?.communicationError?.body?.code === API_RESPONSE_CODE_DOCUMENT_NOT_FOUND) {
                    return createEntityDocument();
                }
            });
        } else {
            // Does not exist - create
            promise = createEntityDocument();
        }
        const asset = await promise;
        return asset;
    }

    async uploadAllImageFieldsAndSetAssetsToEntity(
        allFields: IField[], ownerEntityId: string, ownerEntity: any, existingOwnerEntity: any,
        documentEntityType: DocumentEntityType, progressSubject: Subject<IProgressData>
    ): Promise<IFieldIdWithAsset[]> {
        const fieldIdsWithAssets: IFieldIdWithAsset[] = [];
        const imageFields = allFields.filter(x => x.type === 'image' && ownerEntity[x.name] != undefined);
        for (let i = 0; i < imageFields.length; i++) {
            const field = imageFields[i];
            const croppedImageEventArgs = ownerEntity[field.name] as ICroppedImageEventArgs;
            const percentage = Math.round(100 * ((i + 1) / imageFields.length)) || 0;
            progressSubject.next({
                current: i + 1, total: imageFields.length, currentPercentage: percentage,
                currentId: field.id, currentName: field.label
            });
            try {
                const asset = await this.uploadImageField(
                    ownerEntityId, existingOwnerEntity, documentEntityType, field, croppedImageEventArgs.byteArray
                );
                ownerEntity[field.name] = asset.id;
                fieldIdsWithAssets.push({ fieldId: field.id, asset: asset });
            } catch (err) {
                progressSubject.complete();
                throw err;
            }
        }
        // const fieldsWithoutFileToBeDeleted = allFields.filter(x => x.type === 'image' && ownerEntity[x.name] == undefined && existingOwnerEntity[x.name] != undefined);
        // await Promise.all(fieldsWithoutFileToBeDeleted.map(
        //     field => this.apiSvc.deleteDocument(existingOwnerEntity[field.name])
        // ));
        progressSubject.complete();
        return fieldIdsWithAssets;
    }

    // async uploadAllImageFields(
    //     allFields: IField[], ownerEntityId: string, groupedFieldsObjectWithChanges: any
    // ): Promise<IFieldIdWithAsset[]> {
    //     const result: IFieldIdWithAsset[] = [];
    //     for (const field of allFields) {
    //         if (field.type === 'image') {
    //             const croppedImageEventArgs = groupedFieldsObjectWithChanges[field.name] as ICroppedImageEventArgs;
    //             if (croppedImageEventArgs) {
    //                 const asset = await this.uploadImageField(ownerEntityId, croppedImageEventArgs.byteArray);
    //                 result.push({ fieldId: field.id, asset: asset });
    //             }
    //         }
    //     }
    //     return result;
    // }

    // setUploadedImageFieldsAssetsToEntity(allFields: IField[], fieldIdsWithAssets: IFieldIdWithAsset[], entity: any): void {
    //     for (const item of fieldIdsWithAssets) {
    //         const field = allFields.find(x => x.id === item.fieldId);
    //         entity[field.name] = item.asset.id;
    //     }
    // }

    async downloadDocument(documentId: string): Promise<void> {
        const url = await this.getDocumentUrl(documentId);
        if (!url) { return; }
        window.open(url, '_blank');
    }

    async getDocumentUrl(documentId: string) {
        if (!documentId) { return; }
        // without the catch, any endpoint error causes the service to stop the execution
        const tokenResponse = await this.apiSvc.getDocumentDownloadToken(documentId).catch(() => { return null as IGetDocumentDownloadTokenResponse; });
        if (!tokenResponse) { return; }
        const url = this.apiSvc.getDocumentFileDownloadUrl(documentId, tokenResponse.token);
        return url;
    }

    setAllDocumentFieldsDownloadLinks(allFields: IField[], entity: any, setDocumentFieldDataSubject: Subject<ISetDocumentFieldData>): void {
        const documentFields = allFields.filter(x => x.type === 'document');
        for (const field of documentFields) {
            const docId = entity[field.name];
            if (docId) {
                setDocumentFieldDataSubject.next({ documentId: docId, fieldId: field.id, link: docId, error: '' });
            }
        }
    }

    async loadFloorPlanImageFieldsContent(
        allFields: IField[], floorPlan: IFloorPlan, setImageFieldDataSubject: Subject<ISetImageFieldData>
    ): Promise<void> {
        if (!allFields || !floorPlan || !setImageFieldDataSubject) {
            return;
        }
        const imageField = allFields.find(x => x.type === 'image');
        const imgDownloadTokenRes = await this.apiSvc.getFloorPlanToken(floorPlan.id);
        const downloadUrl = this.apiSvc.getFloorPlanFileDownloadUrl(floorPlan.id, imgDownloadTokenRes.token);
        setImageFieldDataSubject.next({ fieldId: imageField.id, href: downloadUrl });
    }

    async loadAllImageFieldsContent(
        allFields: IField[], entity: any, setImageFieldDataSubject: Subject<ISetImageFieldData>
    ): Promise<void> {
        const imageFields = allFields.filter(x => x.type === 'image');
        for (const field of imageFields) {
            const docId = entity[field.name];
            if (docId) {
                // without the catch, any endpoint error causes the service to stop the execution
                const docDownloadTokenRes = await this.apiSvc.getDocumentDownloadToken(docId).catch(() => { return null as IGetDocumentDownloadTokenResponse; });
                if (docDownloadTokenRes) {
                    const downloadUrl = this.apiSvc.getDocumentFileDownloadUrl(docId, docDownloadTokenRes.token);
                    setImageFieldDataSubject.next({ fieldId: field.id, href: downloadUrl });
                }
            }
        }
    }

    formatDateWithCurrentUserSettings(date: string): string {
        const currentUser = this.apiSvc.getCurrentUser();
        return this.inputFieldSvc.getDateWithTimezone(date, '', currentUser.datetime_format);
    }

    getDebouncedDistinctObservable<T>(source: Observable<T>, interval: number): Observable<T> {
        return source.pipe(
            debounceTime(interval),
            distinctUntilChanged()
        );
    }

    createMultiSelectFieldValueAndOptions(
        items: IObjectWithId[], id: string, labelTranslationKey?: TranslationKey, name?: string
    ): { field: IField, options: IInputFieldOptions } {
        const ids = items.map(x => x.id);
        const field = {
            id: id, name: id || name, editable: true, mandatory_for_planners: false, component: FieldComponentType.default,
            label: labelTranslationKey ? this.translate(labelTranslationKey) : null,
            schema: { type: 'array', items: { type: 'string', enum: ids } }
        } as IField;
        const formsTranslations: IValueTranslation = {};
        items.forEach(x => formsTranslations[x.id] = x.name);
        const currentUserLanguage = this.apiSvc.getCurrentUser().language;
        const fieldOptions = { translations: { [currentUserLanguage]: formsTranslations } } as IInputFieldOptions;
        return { field: field, options: fieldOptions };
    }

    createMultiSelectFieldValueWithAttributeTranslations(
        items: IObjectWithId[], id: string, labelTranslationKey?: TranslationKey | TranslationMapKey, name?: string
    ): IField {
        const ids = items.map(x => x.id);
        const field = {
            id: id, name: id || name, editable: true, mandatory_for_planners: false, component: FieldComponentType.default,
            label: labelTranslationKey ? this.translate(labelTranslationKey) : null,
            schema: { type: 'array', items: { type: 'string', enum: ids } }
        } as IField;
        // const formsTranslations: IValueTranslation = {};
        // items.forEach(x => formsTranslations[x.id] = x.name);
        const currentUserLanguage = this.apiSvc.getCurrentUser().language;
        field.attributeTranslations = items.map(x => ({
            value: x.id,
            trans: { [currentUserLanguage]: x.name } as IValueTranslation,
        } as IAttributeTranslation));
        return field;
    }

    createDefaultObjectEnumInputFieldValueReportingId<Data extends IObjectWithId>(allItems: Data[], selectedId: string, sortItems = true): IObjectEnumInputFieldValue {
        return this.createDefaultObjectEnumInputFieldValue(allItems, selectedId, 'id', undefined, sortItems);
    }

    createDefaultObjectEnumInputFieldValue<Data extends IObjectWithId>(
        allItems: Data[], selectedId: string, reportProperty?: '' | keyof Data, displayProperty: keyof Data = 'name',
        sortItems = true
    ): IObjectEnumInputFieldValue<Data> {
        if (sortItems) {
            this.sortAlphabetically(allItems, x => x.name);
        }
        const result: IObjectEnumInputFieldValue<Data> = {
            displayProperty: `${displayProperty as any}`,
            items: allItems,
            selected: allItems.find(x => x.id === selectedId)
        };
        if (reportProperty) {
            result.reportProperty = `${reportProperty as any}`;
        }
        return result;
    }

    createDefaultObjectEnumFieldNoNull(id: string, labelTranslationKey?: TranslationKey | TranslationMapKey, name?: string): IField {
        const field = this.createDefaultObjectEnumField(id, labelTranslationKey, name);
        field.schema = { type: 'object' };
        return field;
    }

    createDefaultObjectEnumField(id: string, labelTranslationKey?: TranslationKey | TranslationMapKey, name?: string): IField {
        const field = {
            id: id, name: name || id,
            mandatory_for_planners: false, editable: true, component: FieldComponentType.objectEnum,
            schema: { type: ['object', 'null'] }
        } as IField;
        if (labelTranslationKey) {
            field.label = this.translateText(labelTranslationKey);
        }
        return field;
    }

    getObjectWithIdKeysMappedToFieldNames(objectwWithIdKeys: object, fields: IField[]): Record<string, any> {
        // Converts this kind of objects containing field ids for keys instead of names
        // {
        //     "5da34565634634": "John",
        //     "5da34569752359": "Doe",
        //     "5dade4ad6e5d3e": "john@mail.com",
        //     "id": "87429649871234" // some of the keys can be actual field names - we will keep them
        // }
        // To field names
        // {
        //     first_name: "John",
        //     last_name: "Doe",
        //     email: "john@email.com",
        //     "id": "87429649871234"
        // }
        const result = {} as Record<string, any>;
        const keys = Object.keys(objectwWithIdKeys);
        for (const key of keys) {
            const field = fields.find(x => x.id === key);
            const newKey = field ? field.name : key;
            result[newKey] = (objectwWithIdKeys as any)[key];
        }
        return result;
    }

    getStartOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    getStartOfDay(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    }

    getEndOfDay(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    }

    getDatePart(date: Date): string {
        const iso = date.toISOString();
        return iso.substr(0, 10) + 'T00:00:00Z';
    }

    setDateTimeFieldsTimeZone(fields: IField[], timeZone: string): void {
        fields.filter(x => this.isDateTimeField(x)).forEach(x => x.timezone = timeZone);
    }

    setObjectDateTimeFieldsTimeZone(obj: object, fields: IField[], timeZone: string): void {
        const objAny = obj as any;
        for (const field of fields) {
            if (this.isDateTimeField(field)) {
                const key = field.name;
                const objValue = objAny[key];
                if (objValue) {
                    objAny[key] = this.getDateTimeWithTimeZone(objValue, timeZone);
                }
            }
        }
    }

    isDateTimeField(field: IField): boolean {
        return field.schema && field.schema.format === 'date-time';
    }

    getDateTimeWithTimeZone(dateValue: string | Date, timeZone: string): string {
        const date = (typeof dateValue === 'string') ? new Date(dateValue) : dateValue;
        const dateTimeStringWithoutTimeoffset = this.inputFieldSvc.getDateTimeStringWithoutTimeOffset(date);
        const dateWithTimeZone = this.inputFieldSvc.getDateWithTimezone(dateTimeStringWithoutTimeoffset, timeZone);
        return dateWithTimeZone;
    }

    convertDateTimeToTimeZone(dateValue: string | Date, timeZone: string): string {
        const date = (typeof dateValue === 'string') ? new Date(dateValue) : dateValue;
        const dateToTimeZone = this.inputFieldSvc.getDateWithTimezone(date, timeZone);
        return dateToTimeZone;
    }

    getPreferredLanguageField(fields: IField[]): IField {
        return fields.find(x => x.name === this.preferredLanguageFieldName);
    }

    getProfilePageIdField(fields: IField[]): IField {
        const profilePageIdFieldName = this.getProfilePageIdFieldName();
        return fields.find(x => x.name === profilePageIdFieldName);
    }

    async modifyPreferredLanguageField(allFields: IField[], availableLanguages: string[]): Promise<void> {
        const userLang = this.getCurrentUser().language;
        const preferredLanguageField = this.getPreferredLanguageField(allFields);
        if (!preferredLanguageField) {
            return;
        }
        // The server returns preferred_language field type as 'preference' which is unknown for the front-end and is actually 'select'
        preferredLanguageField.type = 'select';
        preferredLanguageField.schema.enum = availableLanguages;
        preferredLanguageField.attributeTranslations ||= [];
        const allLangs = await this.getAllSupportedIsoLanguages();
        for (const lang of availableLanguages) {
            const isoLang = allLangs.find(x => x.id === lang);
            if (isoLang) {
                const existingTranslation = preferredLanguageField.attributeTranslations.find(x => x.value === isoLang.id);
                if (existingTranslation) {
                    existingTranslation.trans = { [userLang]: isoLang.label };
                } else {
                    preferredLanguageField.attributeTranslations.push({
                        value: isoLang.id,
                        trans: { [userLang]: isoLang.label },
                    });
                }
            }
        }
    }

    async modifyPreferredLanguageGroupedField(preferredLanguageField: IField, entity: any, enabledLanguages: string[]): Promise<void> {
        preferredLanguageField.component = FieldComponentType.objectEnum;
        preferredLanguageField.schema = { type: 'object' };
        const allLangs = await this.getAllSupportedIsoLanguages();
        const eventLanguageItems = this.getSelectedLanguageItems(enabledLanguages, allLangs);
        const currentValue = entity[preferredLanguageField.name];
        entity[preferredLanguageField.name] = {
            displayProperty: 'label',
            items: eventLanguageItems,
            selected: eventLanguageItems.find(x => x.id === currentValue),
            reportProperty: 'id'
        } as IObjectEnumInputFieldValue;
    }

    isApiAuthenticationError(err: IErrorNotification): boolean {
        const errCode = err?.error?.communicationError?.body?.code;
        if (errCode === 'CouldNotAuthenticate') {
            return true;
        }
        return false;
    }

    addParticipantStageIdField(fields: IField[], allStages: IStage[]): void {
        // filter out eventual field stage_id
        const stageField = this.getParticipantStageFieldForDecisionStep(fields, allStages);
        // add artificial field stage_name that can't be edited
        fields.push(stageField);
    }

    getParticipantStageFieldForDecisionStep(fields: IField[], allStages: IStage[]) {
        for (let i = fields.length - 1; i >= 0; i--) {
            if (fields[i].name === 'stage_id') {
                fields.splice(i, 1);
            }
        }
        const stageField = this.createSelectField({
            label: this.translate(TRANSLATION_MAP.STAGE),
            name: 'stage_id',
            id: 'stage_id'
        }, allStages.reduce((result, stage) => {
            result[stage.id] = stage.name;
            return result;
        }, {} as any));
        return stageField;
    }

    async addProfilePageIdField(fields: IField[], keepOriginalId?: boolean, allProfilePages?: IProfilePage[]): Promise<void> {
        let originalFieldId: string;
        const profilePageIdFieldName = this.getProfilePageIdFieldName();
        allProfilePages ||= await this.getAllProfilePages();
        for (let i = fields.length - 1; i >= 0; i--) {
            if (fields[i].name === profilePageIdFieldName) {
                if (keepOriginalId) {
                    originalFieldId = fields[i].id;
                }
                fields.splice(i, 1);
            }
        }
        fields.push({
            id: originalFieldId ?? profilePageIdFieldName, name: profilePageIdFieldName, editable: false, category: '',
            label: this.translate(TranslationKey.profilePage),
            schema: { type: 'string', enum: allProfilePages.map(x => x.id) }, builtin: true, visible_for_planners: true
        });
    }


    addFieldOptionsForStageField(allStages: IStage[], fieldsOptions: IFieldIdWithOptions[]): void {
        const currentUserLanguage = this.apiSvc.getCurrentUser().language;
        const stagesMap: IValueTranslation = {};
        allStages.forEach(x => stagesMap[x.id] = x.name);
        const stageFieldOptions: IFieldIdWithOptions = {
            fieldId: 'stage_id',
            options: {
                translations: {
                    [currentUserLanguage]: stagesMap
                }
            }
        };
        fieldsOptions.push(stageFieldOptions);
    }

    addParticipantProfilePageIdField(fields: IField[], allProfilePages: IProfilePage[]): void {
        // filter out eventual field profile_page_id
        const profilePageIdFieldName = this.getProfilePageIdFieldName();
        for (let i = fields.length - 1; i >= 0; i--) {
            if (fields[i].name === profilePageIdFieldName) {
                fields.splice(i, 1);
            }
        }
        fields.push({
            id: profilePageIdFieldName, name: profilePageIdFieldName, editable: false, category: '',
            label: this.translate(TranslationKey.profilePage),
            schema: { type: 'string', enum: allProfilePages.map(x => x.id) }, builtin: true, visible_for_planners: true
        });
    }

    async addFieldOptionsForProfilePageField(
        fieldsOptions: IFieldIdWithOptions[], allFields?: IField[], profilePages?: IProfilePage[]
    ): Promise<void> {
        const profilePageIdFieldName = this.getProfilePageIdFieldName();
        let originalProfilePageFieldId: string;
        if (allFields) {
            const profilePageField = allFields.find(x => x.name === profilePageIdFieldName);
            originalProfilePageFieldId = profilePageField?.id;
        }
        profilePages ||= await this.getAllProfilePages();
        const currentUserLanguage = this.apiSvc.getCurrentUser().language;
        const profilePagesMap: IValueTranslation = {};
        profilePages.forEach(x => profilePagesMap[x.id] = x.name);
        const stageFieldOptions: IFieldIdWithOptions = {
            fieldId: originalProfilePageFieldId ?? profilePageIdFieldName,
            options: {
                translations: {
                    [currentUserLanguage]: profilePagesMap
                }
            }
        };
        fieldsOptions.push(stageFieldOptions);
    }

    async addFieldOptionsForPreferredLanguageField(
        languageCodes: string[], fieldsOptions: IFieldIdWithOptions[], fieldId: string
    ): Promise<void> {
        const allIsoLanguages = await this.getAllSupportedIsoLanguages();
        const supportedIsoLanguages = allIsoLanguages.filter(x => languageCodes.includes(x.id));
        const currentUserLanguage = this.apiSvc.getCurrentUser().language;
        const languagesMap: IValueTranslation = {};
        supportedIsoLanguages.forEach(x => languagesMap[x.id] = x.label);
        const prefferredLanguageFieldOptions: IFieldIdWithOptions = {
            fieldId: fieldId,
            options: {
                translations: {
                    [currentUserLanguage]: languagesMap
                }
            }
        };
        fieldsOptions.push(prefferredLanguageFieldOptions);
    }

    sortAlphabetically<T>(array: T[], valueSelector: (item: T) => string): void {
        if (!array) {
            return;
        }
        if (array.length <= 1) {
            return;
        }
        array.sort((left, right) =>
            // Convert value selectors to strings to avoid throws of toLowerCase()
            (('' + valueSelector(left)) || '').toLowerCase().localeCompare((('' + valueSelector(right)) || '').toLowerCase())
        );
    }

    getLoadingOnReportLoaded(response: IGetReportResponse): boolean {
        // TODO: Should we change IGetReportResponse so it contains objec for the data that came from the server
        //       and another one for calculated values like this flag (named like stillLoading) ?
        if (response.status === 'running') {
            // Data is available but still waiting for most recent one - report that we are still loading
            return true;
        }
        // Response status is "cached" or "error" - report that loading finished
        return false;
    }

    disposePreviewImgResources(): void {
        this.objectUrls.clear();
    }

    async getPreviewImgSrc(previewUrl: string, defaultUrl: string): Promise<any> {
        if (!previewUrl) {
            return defaultUrl;
        }
        let imgSrc: string;
        try {
            const thumbnailUrl = this.prefixWithUrlForResources(previewUrl);
            const blob = await this.apiSvc.getFile(thumbnailUrl);
            const objectUrl = URL.createObjectURL(blob);
            this.objectUrls.add(objectUrl);
            imgSrc = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl) as any;
        } catch (err) {
            imgSrc = defaultUrl;
        }
        return imgSrc;
    }

    createDefaultGrapesJsJSON(): IGrapesJsJSON {
        const grapesJson: IGrapesJsJSON = {
            assets: [],
            components: [],
            styles: [],
            translations: {} as any
        };
        // TODO: Remove this when we decide to change server validation
        grapesJson.translations = { page: {}, layout: {} };
        return grapesJson;
    }

    async showNameDialog(existingName?: string): Promise<string> {
        const groupedFields: IAzavistaGroupedFieldsComponentData = {
            editModeForAllFields: true, expandPanels: true, singleColumn: true, showActionButtons: true,
            fieldsWithEntity: {
                entity: {},
                fields: []
            }
        };
        const fieldName = 'name';
        groupedFields.fieldsWithEntity.entity[fieldName] = existingName || '';
        groupedFields.fieldsWithEntity.fields.push(
            {
                id: fieldName, name: fieldName, label: this.translate(TranslationKey.name),
                category: '', editable: true, schema: { type: 'string' }
            } as IField
        );
        const actionSubject = new Subject<IValueChangesWithObject>();
        const newEntityData: ICreateNewEntityComponentData = {
            groupedFields: groupedFields, actionSubject: actionSubject,
            title: ''
        };
        return new Promise<string>(resolve => {
            const dialogRef = this.matDialog.open(
                CreateNewEntityComponent, {
                data: newEntityData,
                width: this.getNewDialogCssWidth()
            });
            const subscription = actionSubject.subscribe({
                next: changes => {
                    subscription.unsubscribe();
                    dialogRef.close();
                    resolve(changes?.obj?.[fieldName]);
                }
            });
            dialogRef.afterClosed().subscribe({
                next: () => {
                    subscription.unsubscribe();
                }
            });
        });
    }

    async getNameSelection(
        currentName?: string,
        selectedItemsCountData?: IAzavistaSelectedItemsCountData,
    ): Promise<ISelectNameDialogResult> {
        let promiseResolve: (value?: ISelectNameDialogResult) => void;
        const promise = new Promise<ISelectNameDialogResult>(resolve => {
            promiseResolve = resolve;
        });
        const groupedFields: IAzavistaGroupedFieldsComponentData = {
            editModeForAllFields: true, expandPanels: true, singleColumn: true, showActionButtons: true,
            fieldsWithEntity: {
                entity: {},
                fields: []
            },
            disableAutoOrdering: true,
            selectedItemsCountData: selectedItemsCountData
        };

        groupedFields.fieldsWithEntity.entity.name = currentName || '';
        groupedFields.fieldsWithEntity.fields.push(
            {
                id: 'name', name: 'name', label: this.translate(TRANSLATION_MAP.NAME), category: '', editable: true, schema: { type: 'string' },
                mandatory_for_planners: true
            } as IField
        );


        const actionSubject = new Subject<IValueChangesWithObject>();
        const newEntityData: ICreateNewEntityComponentData = {
            groupedFields: groupedFields, actionSubject: actionSubject,
            title: ''
        };
        const dialogRef = this.matDialog.open(CreateNewEntityComponent, {
            data: newEntityData,
            width: this.getNewDialogCssWidth()
        });
        const actionSubscription = actionSubject.subscribe({
            next: async data => {
                if (!data) {
                    // Close button is clicked
                    actionSubscription.unsubscribe();
                    dialogRef.close();
                    return promiseResolve();
                }
                const obj = data.obj as ISelectNameDialogResult;
                if (!obj) {
                    actionSubscription.unsubscribe();
                    dialogRef.close();
                    return promiseResolve();
                }
                actionSubscription.unsubscribe();
                dialogRef.close();
                return promiseResolve(obj);
            }
        });
        return promise;
    }

    async getNameOrThemeSelection(
        showName: boolean, showThemes: boolean, currentName?: string, allThemes?: ITheme[], selectedThemeId?: string,
        selectedItemsCountData?: IAzavistaSelectedItemsCountData
    ): Promise<ISelectNameOrThemeDialogResult> {
        let promiseResolve: (value?: ISelectNameOrThemeDialogResult) => void;
        const promise = new Promise<ISelectNameOrThemeDialogResult>(resolve => {
            promiseResolve = resolve;
        });
        const groupedFields: IAzavistaGroupedFieldsComponentData = {
            editModeForAllFields: true, expandPanels: true, singleColumn: true, showActionButtons: true,
            fieldsWithEntity: {
                entity: {},
                fields: []
            },
            disableAutoOrdering: true,
            selectedItemsCountData: selectedItemsCountData
        };
        if (showName) {
            groupedFields.fieldsWithEntity.entity.name = currentName || '';
            groupedFields.fieldsWithEntity.fields.push(
                {
                    id: 'name', name: 'name', label: this.translate(TranslationKey.name), category: '', editable: true, schema: { type: 'string' },
                    mandatory_for_planners: true
                } as IField
            );
        }
        if (showThemes) {
            // const themesValue: IObjectEnumInputFieldValue = {
            const themesValue: any = {
                items: allThemes,
                selected: allThemes.find(x => x.id === selectedThemeId),
                displayProperty: 'name'
            };
            groupedFields.fieldsWithEntity.entity.theme = themesValue;
            groupedFields.fieldsWithEntity.fields.push(
                {
                    id: 'theme', name: 'theme', label: this.translate(TranslationKey.theme), category: '', editable: true, component: 'object_enum',
                    schema: { type: 'object' }, mandatory_for_planners: true
                } as IField
            );
        }

        const actionSubject = new Subject<IValueChangesWithObject>();
        const newEntityData: ICreateNewEntityComponentData = {
            groupedFields: groupedFields, actionSubject: actionSubject,
            title: ''
        };
        const newTeamDialogRef = this.matDialog.open(CreateNewEntityComponent, {
            data: newEntityData,
            width: this.getNewDialogCssWidth()
        });
        const actionSubscription = actionSubject.subscribe({
            next: async data => {
                if (!data) {
                    // Close button is clicked
                    actionSubscription.unsubscribe();
                    newTeamDialogRef.close();
                    return promiseResolve();
                }
                const obj = data.obj as ISelectNameOrThemeDialogResult;
                if (!obj) {
                    actionSubscription.unsubscribe();
                    newTeamDialogRef.close();
                    return promiseResolve();
                }
                actionSubscription.unsubscribe();
                newTeamDialogRef.close();
                if (allThemes) {
                    // allThemes will have value only when new page is created
                    // If the name of existing is changed allThemes will be undefined
                    // If drop-down is not changed it will not set the value
                    obj.theme = obj.theme || allThemes[0];
                }
                return promiseResolve(obj);
            }
        });
        return promise;
    }

    createAdvancedSearchRequestFromTableWithoutSorting(
        allItemsSelected: boolean,
        selectedIds: any[],
        tableSettingsChangedData: ITableSettingsChangedData
    ): IAdvancedSearchRequest {
        const result = this.createAdvancedSearchRequestFromTable(allItemsSelected, selectedIds, tableSettingsChangedData);
        delete result.sortFieldName;
        delete result.sortDirection;
        return result;
    }

    createAdvancedSearchRequestFromTable(
        allItemsSelected: boolean,
        selectedIds: any[],
        tableSettingsChangedData: ITableSettingsChangedData
    ): IAdvancedSearchRequest {
        const req: IAdvancedSearchRequest = {} as IAdvancedSearchRequest;
        if (!allItemsSelected) {
            // Not everything is selected - create filter with selected ids only
            req.advancedQuery = this.createIdInSearchParams(selectedIds);
            if (tableSettingsChangedData?.paging) {
                req.limit = tableSettingsChangedData.paging.limit;
                req.offset = tableSettingsChangedData.paging.offset;
            }
        } else if (tableSettingsChangedData && tableSettingsChangedData.advancedSearch) {
            // Everything is selected and there is advanced search filter
            const advReq: IAdvancedSearchRequest = this.createAdvancedSearchRequest(
                tableSettingsChangedData
            ) as IAdvancedSearchRequest;
            req.advancedQuery = advReq.advancedQuery;
            req.quickSearchText = advReq.quickSearchText;
            if (advReq.sortDirection) {
                req.sortDirection = advReq.sortDirection;
            }
            if (advReq.sortFieldName) {
                req.sortFieldName = advReq.sortFieldName;
            }
        }
        return req;
    }

    isWhiteSpace(value: string): boolean {
        if (!value) {
            return true;
        }
        if (!value.trim()) {
            return true;
        }

        return false;
    }

    isAnyFalsyString(values: string[]): boolean {
        return values.some(x => this.isFalsyString(x));
    }

    isFalsyString(value: string): boolean {
        if (value === null || value === undefined || value === '') {
            return true;
        }

        const isEmptyString = !value.trim();
        return isEmptyString;
    }

    getFieldsCategories(fields: IField[]): string[] {
        const categories = this.getDistinctArray(fields.map(x => x.category));
        return categories;
    }

    getDistinctArray<T>(source: T[], keySelector?: (item: T) => any): T[] {
        if (!keySelector) {
            return Array.from(new Set(source));
        }
        const distinctArr: T[] = [];
        const hashObj = new Set();
        for (const item of source) {
            const key = keySelector ? keySelector(item) : item;
            if (!hashObj.has(key)) {
                hashObj.add(key);
                distinctArr.push(item);
            }
        }
        return distinctArr;
    }

    getDistinctArrayNoFalsyValues<T>(source: T[], keySelector?: (item: T) => any): T[] {
        return this.getDistinctArray(source, keySelector).filter(x => !!x);
    }

    getCurrentDate(): Date {
        return new Date();
    }

    getDefaultLanguage(): string {
        return 'en-US';
    }

    getSelectedLanguageItems(selectedIds: string[], languageItems: ILanguageItem[]): ILanguageItem[] {
        const selectedItems: ILanguageItem[] = [];
        for (const eventLang of selectedIds) {
            const isoLang = languageItems.find(x => x.id === eventLang);
            if (isoLang) {
                selectedItems.push(isoLang);
            }
        }
        return selectedItems;
    }

    async getUserFriendlyLanguagesArray(): Promise<string[]> {
        const allSupportedLanguages = await this.getAllSupportedIsoLanguages();
        return allSupportedLanguages.map(x => x.label);
    }

    async getLanguageIsoIdFromLabel(label: string): Promise<string> {
        const isoLanguageItem = (await this.getAllSupportedIsoLanguages()).find(x => x.label === label);
        return isoLanguageItem.id;
    }

    async addUserFriendlyLanguageField(fields: IField[], eventLanguages?: string[]): Promise<void> {
        const preferredLanguageField = this.getPreferredLanguageField(fields);
        if (preferredLanguageField && preferredLanguageField.schema && preferredLanguageField.schema.enum) {
            let userFriendlyLanguages = await this.getUserFriendlyLanguagesArray();
            if (eventLanguages) {
                const allIsoLanguages = await this.getAllSupportedIsoLanguages();
                const eventSupportedIsoLanguages = allIsoLanguages.filter(x => eventLanguages.includes(x.id));
                userFriendlyLanguages = eventSupportedIsoLanguages.map(x => x.label);
            }
            preferredLanguageField.schema.enum = userFriendlyLanguages;
        }
    }

    async getAllProfilePages(): Promise<IProfilePage[]> {
        return (await this.apiSvc.getAllProfilePages()).profile_pages;
    }

    getAllSupportedCurrencies(): string[] {
        return [
            'GBP', 'EUR', 'USD', 'CHF', 'SGD', 'AUD', 'JPY', 'CAD', 'SEK', 'NOK'
        ];
    }

    getNow(): number {
        return Date.now();
    }

    addExampleDateToFormat(format: string, userLanguage: string): string {
        const exampleDate = this.getNow();
        const formattedExampleDate = this.dateFormatPipe.transform(exampleDate, format, userLanguage);
        const formatWithExampleDate = `${format} (${formattedExampleDate})`;
        return formatWithExampleDate;
    }

    createObjectEnumWithDateFormatEntityValue(items: any[], user: IUser, selectedUserKeySelector: (u: IUser) => string)
        : IObjectEnumInputFieldValue {
        const timeFormatsItems = items.map(x => (
            { id: x, label: this.addExampleDateToFormat(x, user.language) } as IIdWithLabel
        ));
        const result: IObjectEnumInputFieldValue = {
            displayProperty: 'label', reportProperty: 'id',
            items: timeFormatsItems,
            selected: timeFormatsItems.find(x => x.id === selectedUserKeySelector(user))
        };
        return result;
    }

    getAppSupportedDateTimeFormats(): string[] {
        const datetimeFormats = [
            'DD-MM-YYYY HH:mm:ss',
            'DD-MM-YYYY HH:mm',
            'MM-DD-YYYY h:mm:ss A',
            'MM-DD-YYYY h:mm A',
            'LLL',
            'lll',
            'LLLL',
            'llll',
            'LL HH:mm',
            'ddd LL HH:mm',
            'ddd, LL HH:mm',
            'dddd LL HH:mm',
            'dddd, LL HH:mm'
        ];
        return datetimeFormats;
    }

    getAppSupportedDateFormats(): string[] {
        return ['DD-MM-YYYY', 'MM-DD-YYYY', 'L', 'l', 'LL', 'll'];
    }

    getAppSupportedTimeFormats(): string[] {
        return ['hh:mm:ss A', 'HH:mm', 'LT', 'LTS'];
    }

    getAppSupportedLanguages(): ILanguageItem[] {
        const languages: ILanguageItem[] = [
            { id: 'en-US', label: 'English' },
            { id: 'es-ES', label: 'Spanish' },
            { id: 'fr-FR', label: 'French' },
            { id: 'nl-NL', label: 'Dutch' },
            { id: 'it-IT', label: 'Italian' },
            { id: 'de-DE', label: 'German' }
        ];
        return languages;
    }

    // TODO: Remove this when we have API endpoint with constants
    async getAllSupportedIsoLanguages(): Promise<ILanguageItem[]> {
        return [
            { id: 'en-US', label: 'English (United States)' },
            { id: 'en-GB', label: 'English (Great Britain)' },
            { id: 'de-DE', label: 'German' },
            { id: 'nl-NL', label: 'Dutch' },
            { id: 'fr-FR', label: 'French' },
            { id: 'es-ES', label: 'Spanish' },
            { id: 'ca-ES', label: 'Catalan (Spain)' },
            { id: 'da-DK', label: 'Danish' },
            { id: 'it-IT', label: 'Italian' },
            { id: 'pt-PT', label: 'Portuguese' },
            { id: 'pt-BR', label: 'Portuguese (Brazilizan)' },
            { id: 'sv-SE', label: 'Swedish' },
            { id: 'cs-CZ', label: 'Czech' },
            { id: 'el-GR', label: 'Greek' },
            { id: 'he-IL', label: 'Hebrew' },
            { id: 'hr-HR', label: 'Croatian' },
            { id: 'hu-HU', label: 'Hungarian' },
            { id: 'ja-JP', label: 'Japanese' },
            { id: 'ko-KR', label: 'Korean' },
            { id: 'nn-NO', label: 'Norwegian' },
            { id: 'pl-PL', label: 'Polish' },
            { id: 'ro-RO', label: 'Romanian' },
            { id: 'ru-RU', label: 'Russian' },
            { id: 'fi-FI', label: 'Finnish' },
            { id: 'zh-CN', label: 'Chinese (Simplified)' },
            { id: 'vi-VN', label: 'Vietnamese' },
            { id: 'sk-SK', label: 'Slovak' },
            { id: 'bg-BG', label: 'Bulgarian' },
            { id: 'tr-TR', label: 'Turkish' },
            { id: 'ur-PK', label: 'Urdu' },
            { id: 'hi-IN', label: 'Hindi' },
            { id: 'th-TH', label: 'Thai' },
            { id: 'ms-MY', label: 'Malay' },
            { id: 'id-ID', label: 'Indonesian' },
            { id: 'fil-PH', label: 'Filipino' },
            { id: 'sr-Latn', label: 'Serbian' },
            { id: 'ar-AE', label: 'Arabic' },
        ];
    }

    setFirstPageIfQuickSearchOrFilterChanged(data: ITableSettingsChangedDataForRequest): boolean {
        if (!data.advancedSearch) {
            return false;
        }
        if (data.advancedSearch.quickSearchTextChanged || data.advancedSearch.filterChanged) {
            data.paging.offset = 0;
            return true;
        }
        return false;
    }

    prefixWithUrlForResources(path: string): string {
        let urlPrefix = environment.resourcesUrlPrefix || '';
        if (urlPrefix[urlPrefix.length - 1] === '/' && path[0] === '/') {
            // Resources url prefix and provided path have '/' one after the other - remove it from the prefix
            // So we don't end up with paths like https://something.com//theme/theme-id.css  (double // after the domain name)
            // When url prefix ends with / and path starts with /
            urlPrefix = urlPrefix.substr(0, urlPrefix.length - 1);
        }
        return urlPrefix + path;
    }

    getAppUrlPrefixIncluding30Path(): string {
        const path30 = '/3.0/';
        if (environment.production) {
            return window.location.origin + path30;
        }
        return this.prefixWithUrlForResources(path30);
    }

    createDefaultTableSettingsChangedData(): ITableSettingsChangedData {
        const data: ITableSettingsChangedData = {
            paging: this.createDefaultPagingOptionsForLongLists(),
            sorting: this.createDefaultSortingOptions(),
            advancedSearch: {} as IAzavistaAdvancedSearchComponentChangedData,
            pagingChanged: false, sortingChanged: false
        };
        return data;
    }

    getDefaultAdvancedSearchData(columns: IField[]): IAdvancedSearchData {
        this.sortAlphabetically(columns, (item) => item.label);
        const result: IAdvancedSearchData = {
            advancedFilters: [],
            allFields: columns
        };
        return result;
    }

    getTeamColumns(): IField[] {
        const result: IField[] = [];
        const category = this.translate(TranslationKey.teamFields);
        result.push({
            id: 'name', name: 'name', label: this.translate(TranslationKey.name), category: category,
            schema: { type: 'string' }, editable: true, builtin: true, visible_for_planners: true
        });
        result.push({
            id: 'address', name: 'address', label: this.translate(TranslationKey.address), category: category,
            schema: { type: 'string' }, editable: true, builtin: true, visible_for_planners: true
        });
        result.push({
            id: 'city', name: 'city', label: this.translate(TranslationKey.city), category: category,
            schema: { type: 'string' }, editable: true, builtin: true, visible_for_planners: true
        });
        result.push({
            id: 'country', name: 'country', label: this.translate(TranslationKey.country), category: category,
            schema: { type: 'string' }, editable: true, builtin: true, visible_for_planners: true
        });
        result.push({
            id: 'postal_code', name: 'postal_code', label: this.translate(TranslationKey.postalCode), category: category,
            schema: { type: 'string' }, editable: true, builtin: true, visible_for_planners: true
        });
        result.push({
            id: 'contact_email', name: 'contact_email', label: this.translate(TranslationKey.contactEmail), category: category,
            schema: { type: 'string', format: 'email' }, editable: true, builtin: true, visible_for_planners: true
        });
        return result;
    }

    filterNotAllowed(existing: string[], allowed: string[]): string[] {
        const filtered = existing.filter(x => allowed.indexOf(x) >= 0);
        return filtered;
    }

    getAssignableTeams(availableTeams: ITeam[]): ITeam[] {
        let assignableTeams: ITeam[];
        if (!this.aclSvc.isAdmin()) {
            const teamIdsWithAdminRights = this.aclSvc.getCurrentUserTeamIdsContainingScope(this.rsSvc.getScopeA());
            assignableTeams = availableTeams.filter(x => teamIdsWithAdminRights.indexOf(x.id) >= 0);
        } else {
            assignableTeams = availableTeams;
        }
        return assignableTeams;
    }

    async getOrganizationsByIds(ids: string[]): Promise<ISearchOrganizationsResponse> {
        const searchOrgReq: ISearchOrganizationsRequest = {
            limit: 50, offset: 0
        };
        searchOrgReq.advancedQuery = this.createIdInSearchParams(ids);
        return await this.apiSvc.searchOrganizations(searchOrgReq);
    }

    async setOrganizationNames(entities: (IContact | IEventParticipant)[]): Promise<void> {
        let allOrganizationIds = entities.filter(x => !!x.organization_id).map(x => x.organization_id);
        allOrganizationIds = this.getDistinctArray(allOrganizationIds, x => x);
        if (allOrganizationIds.length === 0) {
            return;
        }
        const allOrganizationsRes = await this.getOrganizationsByIds(allOrganizationIds);
        for (const entity of entities) {
            const org = allOrganizationsRes.organizations.find(x => x.id === entity.organization_id);
            this.setContactOrganizationRelation(entity, org);
        }
    }

    setContactOrganizationRelation(destinationEntity: any, organization: IOrganization): void {
        let selected: IOrganization;
        let orgName: string;
        if (organization?.id) {
            selected = { id: organization.id, name: organization.name } as IOrganization;
            orgName = organization.name;
        } else {
            selected = {} as IOrganization;
        }
        const items: IOrganization[] = [selected];
        const rowAny = destinationEntity as any;
        const orgRelationFieldName = this.getOrganizationRelationFakeFieldId();
        rowAny[orgRelationFieldName] = {
            displayProperty: 'name', items: items, selected: selected
        } as IRelationInputFieldValue;
        const orgNameFieldName = this.getOrganizationNameFakeFieldId();
        rowAny[orgNameFieldName] = orgName;
    }

    setActivityDialogRelation(destinationEntity: any): void {
        destinationEntity.__activity_relation = {
            displayProperty: 'name', items: [], selected: {} as IEventActivity
        } as IRelationInputFieldValue;
    }

    replaceOrganizationIdFieldWithOrganizationRelation(fields: IField[]): void {
        const orgIdFieldName = this.getOrganizationIdFieldName();
        const organizationIdFieldIndex = fields.findIndex(x => x.name === orgIdFieldName);
        if (organizationIdFieldIndex === -1) { // not found
            return;
        }
        const orgIdField = fields[organizationIdFieldIndex];
        fields.splice(organizationIdFieldIndex, 1);
        // Add the special field description representing organization relation
        // The contact must already have attribute named __organizationRelation with the correct value
        fields.push({
            id: this.getOrganizationRelationFakeFieldId(),
            component: 'relation',
            name: this.getOrganizationRelationFakeFieldId(),
            category: orgIdField.category,
            label: orgIdField.label,
            editable: true,
            mandatory_for_planners: orgIdField.mandatory_for_planners,
            schema: { type: ['string', 'object'] },
        } as IField);
    }

    replaceProfileIdFieldWithProfileRelation(fields: IField[]): void {
        const profileIdFieldName = this.getProfilePageIdFieldName();
        const profileIdFieldIndex = fields.findIndex(x => x.name === profileIdFieldName);
        if (profileIdFieldIndex === -1) { // not found
            return;
        }
        const profileIdField = fields[profileIdFieldIndex];
        fields.splice(profileIdFieldIndex, 1);
        fields.push({
            id: this.getProfilePageRelationFakeFieldId(),
            component: 'relation',
            name: this.getProfilePageRelationFakeFieldId(),
            category: profileIdField.category,
            label: profileIdField.label,
            editable: true,
            mandatory_for_planners: profileIdField.mandatory_for_planners,
            schema: { type: ['string', 'object'] },
        } as IField);
    }

    async findSelectedProfilePage(profilePageId: string): Promise<IProfilePage> {
        const profilePages = await this.getAllProfilePages();
        return profilePages.find(x => x.id === profilePageId);
    }

    setContactProfilePageRelation(destinationEntity: any, profilePage: IProfilePage): void {
        let selected: IProfilePage;
        let profilePageName: string;
        if (profilePage) {
            selected = { id: profilePage.id, name: profilePage.name } as IProfilePage;
            profilePageName = profilePage.name;
        } else {
            selected = {} as IProfilePage;
        }
        const items: IProfilePage[] = [selected];
        const rowAny = destinationEntity as any;
        const profileRelationFieldName = this.getProfilePageRelationFakeFieldId();
        rowAny[profileRelationFieldName] = {
            displayProperty: 'name', items: items, selected: selected
        } as IRelationInputFieldValue;
        const profileNameFieldName = this.getProfileNameFakeFieldId();
        rowAny[profileNameFieldName] = profilePageName;
    }

    getOrganizationIdFieldName(): string {
        return BuiltInFieldName.organization_id;
    }

    getProfilePageIdFieldName(): string {
        return 'profile_page_id';
    }

    getLocationAutocompleteFakeFieldId(): string {
        return '__locationAutocomplete';
    }

    getLocationNameFakeFieldId(): string {
        return '__locationName';
    }

    getOrganizationRelationFakeFieldId(): string {
        return '__organizationRelation';
    }

    getOrganizationNameFakeFieldId(): string {
        return '__organizationName';
    }

    getProfilePageRelationFakeFieldId(): string {
        return '__profilePageRelation';
    }

    getProfileNameFakeFieldId(): string {
        return '__profilePageName';
    }

    moveOrganizationRelationToOrganizationId(entity: any): void {
        // Remove __organizationRelation and set its selection.id to organization_id
        const orgRelationFieldName = this.getOrganizationRelationFakeFieldId();
        const organizationRelation = entity[orgRelationFieldName] as IRelationInputFieldValue;
        if (organizationRelation) {
            entity.organization_id = organizationRelation.selected.id;
            delete entity[orgRelationFieldName];
        }
    }

    moveProfileRelationToProfileId(entity: any): void {
        const profileRelationFieldName = this.getProfilePageRelationFakeFieldId();
        const profileRelation = entity[profileRelationFieldName] as IRelationInputFieldValue;
        if (profileRelation) {
            entity.profile_page_id = profileRelation.selected.id;
            delete entity[profileRelationFieldName];
        }
    }

    clone<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    getNewDialogCssWidth(): string {
        return 'auto'; // '50vw';
    }

    createIdInSearchParams(value: any[]): SearchParams {
        return this.createSingleItemSearchParams('id', FieldOperator.in, value);
    }

    createSingleItemSearchParams<DataType = any>(fieldName: keyof DataType, operator: FieldOperator, value: any): SearchParams {
        const params = [{ field: { name: fieldName }, value: value, operator: operator }] as FlatSearchParams;
        return flatSearchToRecursive(params, 'name');
    }

    createSearchParams(criteria: IAdvancedFilterCriteria[]): SearchParams {
        const searchParams = flatSearchToRecursive(criteria as any as FlatSearchParams, 'name') as SearchParams;
        return searchParams;
    }

    createAdvancedSearchRequest<FieldName = string>(data: ITableSettingsChangedDataForRequest): IAdvancedSearchRequest<FieldName> {
        this.setFirstPageIfQuickSearchOrFilterChanged(data);
        const req: IAdvancedSearchRequest<FieldName> = {
            limit: data.paging.limit, offset: data.paging.offset
        };
        if (data.advancedSearch) {
            req.advancedQuery = data.advancedSearch.advancedQuery;
            req.quickSearchText = data.advancedSearch.quickSearchText;
        }
        if (data.sorting?.direction && data.sorting?.column) {
            req.sortDirection = data.sorting.direction as SortDirection;
            const field = data.sorting.column as IField;
            req.sortFieldName = field.name as FieldName;
        }
        return req;
    }

    getSelectedItemsCount(selectedCount: number, allItemsSelected: boolean, allItemsCount: number): number {
        if (allItemsSelected) {
            return allItemsCount;
        }
        return selectedCount;
    }

    getSelectedItemsCountData(selectedCount: number, allItemsSelected: boolean, allItemsCount: number): IAzavistaSelectedItemsCountData {
        const result: IAzavistaSelectedItemsCountData = {
            selectedCount: selectedCount, allSelected: allItemsSelected, totalCount: allItemsCount
        };
        return result;
    }

    // TODO: Move this to common dialogs service
    async showConfirmationDialog(
        title: string, contentLines: string[], hideCancelButton?: boolean, selectedItemsData?: IAzavistaSelectedItemsCountData,
        componentDataOverrides?: Partial<IConfirmationDialogComponentData>,
    ): Promise<IConfirmationDialogComponentData | null> {
        const data: IConfirmationDialogComponentData = {
            title: title, contentLines: contentLines, hideCancelButton: hideCancelButton,
            selectedItemsData: selectedItemsData,
            ...componentDataOverrides
        };
        return new Promise(resolve => {
            this.matDialog.open(ConfirmationDialogComponent, { disableClose: true, data: data, maxWidth: this.getNewDialogCssWidth() })
                .afterClosed().subscribe(async dialogResult => {
                    return resolve(dialogResult);
                });
        });
    }

    async showDownloadFilesDialog(downloadData: IJobFileDownloadData[]) {
        const downloadItems: IDownloadItem[] = downloadData.map(x => ({ fileName: x.name, label: x.name, url: x.url } as IDownloadItem));
        const data: IDownloadFilesDialogComponentData = {
            items: downloadItems
        };
        return this.matDialog.open(DownloadFilesDialogComponent, { data: data, maxWidth: this.getNewDialogCssWidth() });
    }

    handleJobFiles(job: IJob): void {
        // TODO: Callers are having IJob which comes from components lib but here we need IJob which comes from service lib
        if (!job.files && !job.fileDownload) {
            return;
        }
        let jobDownloadData: IJobFileDownloadData[] = job.fileDownload;
        if (!jobDownloadData) {
            jobDownloadData = job.files.map(x => ({ url: x, name: job.label } as IJobFileDownloadData));
        }
        if (jobDownloadData.length === 1) {
            jobDownloadData[0].name = jobDownloadData[0].name.split(' ').join('_');
            this.downloadSvc.saveFile(jobDownloadData[0].url, jobDownloadData[0].name);
        } else if (jobDownloadData.length > 1) {
            this.showDownloadFilesDialog(jobDownloadData);
        }
    }

    getGroups<T, TKey>(items: T[], keySelector: (item: T) => string): IGroup<T>[] {
        const grpMap: { [key: string]: any[] } = {};
        for (const item of items) {
            const key = keySelector(item);
            if (grpMap[key]) {
                grpMap[key].push(item);
            } else {
                grpMap[key] = [item];
            }
        }

        const result: IGroup<T>[] = Object.keys(grpMap).map(x => ({ key: x, items: grpMap[x] } as IGroup<T>));
        return result;
    }

    showSearchItemsDialog(
        pagedResponse: IPagedResponse, idsWithLabel: IIdWithLabel[], titleTranslationKey: TranslationKey,
        searchChangedCallback: (searchChanges: ISearchItemsSettingsChangedData) => Promise<ISearchItemsSearchChangedCallbackResult>,
        afterClosedCallback: (dlgResult: IIdWithLabel[]) => void): void {
        const dialogData: ISearchItemsData = {
            items: idsWithLabel, pagingOptions: this.createPagingOptionsFromPagedResponse(pagedResponse),
            title: this.translate(titleTranslationKey)
        };
        const dialogRef = this.matDialog.open(AzavistaSearchItemsComponent, { data: dialogData });
        const searchChangedSubscription = dialogRef.componentInstance.searchChanged.subscribe({
            next: async (searchChanges: ISearchItemsSettingsChangedData) => {
                if (searchChangedCallback) {
                    const searchChangedCallbackResult = await searchChangedCallback(searchChanges);
                    dialogData.items = searchChangedCallbackResult.idsWithLabel;
                    dialogData.pagingOptions = this.createPagingOptionsFromPagedResponse(searchChangedCallbackResult.pagedResponse);
                }
            }
        });
        dialogRef.afterClosed().subscribe({
            next: (dlgResult: IIdWithLabel[]) => {
                searchChangedSubscription.unsubscribe();
                if (afterClosedCallback) {
                    afterClosedCallback(dlgResult);
                }
            }
        });
    }

    getContactsIdWithLabel(contacts: IContact[]): IIdWithLabel[] {
        const result = contacts.map(x =>
        ({
            id: x.id,
            label: this.normalizeString(x.first_name) + ' ' +
                this.normalizeString(x.last_name) + ' ' +
                this.normalizeString(x.email)
        } as IIdWithLabel)
        );
        return result;
    }

    getTeamsIdWithLabel(teams: ITeam[]): IIdWithLabel[] {
        const result = teams.map(x =>
            ({ id: '' + x.id, label: this.normalizeString(x.name) + ' ' + this.normalizeString(x.contact_email) } as IIdWithLabel)
        );
        return result;
    }

    getObjectsIdWithLabel(objects: { id?: string | number, name?: string }[]): IIdWithLabel[] {
        const result = objects.map(x =>
            ({ id: '' + x.id, label: this.normalizeString(x.name) } as IIdWithLabel)
        );
        return result;
    }

    createIdWithLabelArray<TItem>(
        items: TItem[],
        idSelector: (item: TItem) => string,
        labelSelector: (item: TItem) => string,
        setObject: boolean,
    ): IIdWithLabel[] {
        const result = items.map(x => {
            const item: IIdWithLabel = { id: idSelector(x), label: labelSelector(x) };
            if (setObject) {
                item.object = x;
            }
            return item;
        });
        return result;
    }

    getUsersIdWithLabel(users: IUser[]): IIdWithLabel[] {
        const result = users.map(x =>
            ({ id: '' + x.id, label: this.normalizeString(x.first_name) + ' ' + this.normalizeString(x.last_name) } as IIdWithLabel)
        );
        return result;
    }

    createPagingOptionsFromPagedResponse(pagedResponse: IPagedResponse): IPagingOptions {
        const result: IPagingOptions = {
            limit: pagedResponse.limit, offset: pagedResponse.offset,
            object_count: pagedResponse.object_count, pageSizes: this.getPageSizes()
        };
        return result;
    }

    createDefaultPagedRequest(): IPagedRequest {
        const result: IPagedRequest = {
            limit: this.getDefauiltItemsLimitForPagedRequest(), offset: 0,
        };
        return result;
    }

    createDefaultPagingOptionsForLongLists(): IPagingOptions {
        const result: IPagingOptions = {
            limit: this.getDefauiltItemsLimitForPagedRequest(), offset: 0, object_count: 0, pageSizes: this.getPageSizes()
        };
        return result;
    }

    getDefauiltItemsLimitForPagedRequest(): number {
        return 50;
    }

    createDefaultSortingOptions(): ISortingChangedData {
        const data: ISortingChangedData = { column: '', direction: '' };
        return data;
    }

    translate(key: TranslationMapKey): string {
        return this.translateSvc.instant(key);
    }

    translateText(keyText?: string): string {
        const key = keyText ? keyText.toUpperCase() : '';
        if (!key?.trim()) {
            return '';
        }
        const translation = this.translateSvc.instant(key);
        if (translation === key) {
            return keyText;
        }
        return translation;
    }

    getPageSizes(): number[] {
        return [10, 20, 50];
    }

    normalizeString(value?: string): string {
        return value || '';
    }

    getWebsiteUrl(eventSetingsUrl: string, originUrl?: string): string {
        const origin = originUrl ?? window.location.origin;
        // Remove the "3.0." prefix
        const modifiedOrigin = origin.replace('https://3.0.', 'https://');
        const url = modifiedOrigin + eventSetingsUrl;
        return url;
    }

    getFieldsDisplayOrder(orderedFieldNames: string[], unorderedFields: IField[]): IField[] {
        const orderedFields: IField[] = [];
        orderedFieldNames.forEach(fieldName => {
            const field = unorderedFields.find(x => x.name === fieldName);
            if (field) {
                orderedFields.push(field);
            }
        });
        orderedFields.push(...unorderedFields.filter(x => !orderedFieldNames.includes(x.name)));
        return orderedFields;
    }

    getFieldIdWithOptionsTranslationsForIdOnlyField(
        fieldId: string, targetEntities: any[], reportProperty: string, langKey: string
    ): IFieldIdWithOptions {
        const translationsObject: any = {};
        targetEntities.forEach(x => {
            translationsObject[x.id] = x[reportProperty];
        });
        return {
            fieldId: fieldId,
            options: { translations: { [langKey]: translationsObject } }
        };
    }

    getCreatedAtField(): IField {
        return {
            id: '__created_at', name: 'created_at', label: this.translate(TranslationKey.createdAt), editable: false,
            schema: { type: 'string', format: 'date-time' }
        } as IField;
    }

    getUpdatedAtField(): IField {
        return {
            id: '__updated_at', name: 'updated_at', label: this.translate(TranslationKey.updatedAt), editable: false,
            schema: { type: 'string', format: 'date-time' }
        } as IField;
    }

    filterOutRelationFields(fields: IField[]): IField[] {
        return fields.filter(x => (x.type as any) !== 'relation');
    }

    createSelectField<SelectValue extends string | number>(field: SelectField, enumTranslationsPair: Record<SelectValue, TranslationKey | TranslationMapKey>): IField {
        const enumKeys = Object.keys(enumTranslationsPair);
        return {
            type: 'select',
            editable: true,
            schema: {
                type: typeof enumKeys[0] === 'number' ? 'number' : 'string',
                enum: enumKeys
            },
            ...field,
            attributeTranslations: Object.entries(enumTranslationsPair).map(([enumKey, translationKey]) => ({
                value: enumKey,
                trans: {
                    [this.translateSvc.currentLang]: this.translateText(translationKey as TranslationMapKey)
                }
            }))
        } as IField;
    }

    createBooleanField(field: Omit<IField, 'type' | 'schema'>) {
        return {
            type: 'boolean',
            editable: true,
            schema: {
                type: 'boolean'
            },
            component: 'default',
            ...field,
        } as IField;
    }

    getQuickSearchTextFieldNames(
        fields: IField[],
        displayedIds: string[],
        supportedFieldTypes: InputFieldType[] = ['text', 'email', 'virtual-textarea',]
    ): string[] {
        return fields
            .filter(field => !field.name.endsWith('_id') && displayedIds.includes(field.id) && supportedFieldTypes.includes(this.cmpSharedSvc.getFieldTypeFromField(field)))
            .map(({ name }) => name);
    }

    /**
     * Add `<script>` element to the HTML page
     * @param src
     * @param uniqueId if uniqueId is supplied, then it will replace the existing element with the same ID
     * @returns
     */
    addJsToElement(src: string, uniqueId?: string): HTMLScriptElement {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        if (uniqueId) {
            script.id = uniqueId;
            document.querySelector(`#${uniqueId}`)?.remove();
        }
        this.renderer.appendChild(document.body, script);
        return script;
    }

    /** The chatbot need to be reloaded when user switched between isLoggedIn=false and true */
    loadChatBotScript() {
        // the script and iframe shall be removed to prevent duplicate chatbot
        document.querySelector('#libraria-chatbot').innerHTML = '';
        this.addJsToElement('https://libraria-prod.s3.us-west-1.amazonaws.com/public/embed-chatbot.js', 'embed-chatbot-js');
    }

    getFormattedDateTimeRange(config: GetFormattedDateTimeRangeConfig) {
        const { dateFormat, timezone, startDate, endDate, timeFormat } = config;
        const parsingFormat = 'YYYY-M-D';
        const startDateString = this.inputFieldSvc.getDateWithTimezone(startDate, timezone, parsingFormat);
        const endDateString = this.inputFieldSvc.getDateWithTimezone(endDate, timezone, parsingFormat);

        if (startDateString === endDateString) {
            return `${this.inputFieldSvc.getDateWithTimezone(startDate, timezone, dateFormat)} ${this.inputFieldSvc.getDateWithTimezone(startDate, timezone, timeFormat)} - ${this.inputFieldSvc.getDateWithTimezone(endDate, timezone, timeFormat)}`;
        }
        else {
            const startDateYear = startDateString.split('-')[0];
            const isSameYear = startDateYear === endDateString.split('-')[0];
            const regexToRetrieveYear = new RegExp(`[\/|\\-|[, ]*${startDateYear}`);
            const startDateFormat = this.inputFieldSvc.getDateWithTimezone(startDate, timezone, dateFormat).replace(isSameYear ? regexToRetrieveYear : '', '');
            return `${startDateFormat} - ${this.inputFieldSvc.getDateWithTimezone(endDate, timezone, dateFormat)}`;
        }
    }

    getFileSizeFormat(bytesValue: number) {
        const decimalFormat = `1.0-2`;
        if (bytesValue >= giga) {
            return `${this.decimalPipe.transform(bytesValue / giga, decimalFormat)} GB`;
        }
        if (bytesValue >= mega) {
            return `${this.decimalPipe.transform(bytesValue / mega, decimalFormat)} MB`;
        }
        if (bytesValue >= kilo) {
            return `${this.decimalPipe.transform(bytesValue / kilo, decimalFormat)} KB`;
        }
        return `${this.decimalPipe.transform(bytesValue, decimalFormat)} B`;
    };

    async getEventEventAppsUrls(eventId: string, customerId?: number) {
        const eventApps = await this.apiSvc.getAllEventApps();

        return this.getEventAppsUrls(eventApps, customerId);
    }

    async getEventAppsUrls(eventApps: IEventApp[], customerId?: number) {
        if (!customerId) {
            customerId = (await this.apiSvc.getCustomer())?.id;
        }

        return (eventApps.reduce((record, { slug, name }) => {
            const eventAppUrl = this.getEventAppLink(slug, customerId);
            record[eventAppUrl] = name;
            return record;
        }, {} as EventAppUrlRecord));
    }

    getEventAppLink = (eventAppSlug: string, customerId: number) => {
        return new URL(`a/${customerId}/${eventAppSlug}`, this.getWebsiteUrl('', environment.resourcesUrlPrefix || null)).href;
    };

}

const optionsByFieldName: { [fieldName: string]: DocumentEntityUploadOptions } = {
    profile_image: {
        [DocumentEntityType.eventParticipant]: {
            queryParams: {
                public: true
            }
        }
    }
};

type SelectField = Partial<IField> & Pick<IField, 'label' | 'name'>
